import React from 'react';
import logo from './logo.svg';
import './App.css';

function Privacy() {
  return (
    <div className="App">
      <p>
        <iframe src="./privacy.html" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%', border: 'none' }}></iframe>
      </p>
    </div>
  );
}

export default Privacy;
